define("discourse/plugins/chat/discourse/services/chat-audio-manager", ["exports", "@ember/service", "discourse-common/config/environment", "discourse-common/lib/get-url"], function (_exports, _service, _environment, _getUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.CHAT_SOUNDS = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  const CHAT_SOUNDS = _exports.CHAT_SOUNDS = {
    bell: [{
      src: "/plugins/chat/audio/bell.mp3",
      type: "audio/mpeg"
    }],
    ding: [{
      src: "/plugins/chat/audio/ding.mp3",
      type: "audio/mpeg"
    }]
  };
  const DEFAULT_SOUND_NAME = "bell";
  const createAudioCache = sources => {
    const audio = new Audio();
    audio.pause();
    sources.forEach(_ref => {
      let {
        type,
        src
      } = _ref;
      const source = document.createElement("source");
      source.type = type;
      source.src = (0, _getUrl.getURLWithCDN)(src);
      audio.appendChild(source);
    });
    return audio;
  };
  class ChatAudioManager extends _service.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "_audioCache", {});
    }
    setup() {
      Object.keys(CHAT_SOUNDS).forEach(soundName => {
        this._audioCache[soundName] = createAudioCache(CHAT_SOUNDS[soundName]);
      });
    }
    willDestroy() {
      super.willDestroy(...arguments);
      this._audioCache = {};
    }
    async play(soundName) {
      const audio = this._audioCache[soundName] || this._audioCache[DEFAULT_SOUND_NAME];
      audio.muted = (0, _environment.isTesting)();
      if (!audio.paused) {
        audio.pause();
        if (typeof audio.fastSeek === "function") {
          audio.fastSeek(0);
        } else {
          audio.currentTime = 0;
        }
      }
      try {
        await audio.play();
      } catch (e) {
        if (!(0, _environment.isTesting)()) {
          // eslint-disable-next-line no-console
          console.info("[chat] User needs to interact with DOM before we can play notification sounds.");
        }
      }
    }
  }
  _exports.default = ChatAudioManager;
});